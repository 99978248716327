import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";

export const getKitComponents = createAsyncThunk(
    'kitComponent/fetchAll',
    async ({idKit}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/accounts/kits/${idKit}/components`);
            return response.data.kitComponents;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getKitComponent = createAsyncThunk(
    'kitComponent/fetchOne',
    async ({idKit, idKitComponent}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/accounts/kits/${idKit}/components/${idKitComponent}`);
            return response.data.kitComponent;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postKitComponent = createAsyncThunk(
    'kitComponent/create',
    async ({idKit, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/accounts/kits/${idKit}/components`, data);
            return response.data.kitComponent;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putKitComponent = createAsyncThunk(
    'kitComponent/update',
    async ({idKit, idKitComponent, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.put(`/clients/accounts/kits/${idKit}/components/${idKitComponent}`, data);
            return response.data.kitComponent;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteKitComponent = createAsyncThunk(
    'kitComponent/delete',
    async ({idKit, idKitComponent}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/accounts/kits/${idKit}/components/${idKitComponent}`);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    listLoading: undefined,
    kitComponent: undefined,
    loading: undefined
}

const kitComponentSlice = createSlice({
   name: "kitComponent",
    initialState,
    reducers: {
        resetKitComponentList: state => {
            state.list = initialState.list;
        },
        resetKitComponent: state => {
            state.kit = initialState.kit;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getKitComponents.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getKitComponents.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = undefined;
            })
            .addCase(getKitComponents.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getKitComponent.pending, state => {
                state.loading = true;
            })
            .addCase(getKitComponent.fulfilled, (state, action) => {
                state.kitComponent = action.payload;
                state.loading = undefined;
            })
            .addCase(getKitComponent.rejected, state => {
                state.loading = undefined;
            })
    }
});

export const {resetKitComponentList, resetKitComponent} = kitComponentSlice.actions;

export default kitComponentSlice.reducer;